import { RtxPreset } from '@/enums/RtxPreset';
import { PanoramasType } from '@/enums/PanoramasType';
import { BuildPriority } from '@/enums/BuildPriority';
import { UnrealPanoramaRenderModes } from '@/enums/UnrealPanoramaRenderModes';
import {
  Steps, OptionTypes, getOptionsAndRules as getOptionsAndRulesBase, getRulesForOptions,
} from '@/composables/serviceFormOptions';
import getFlag from '@/helpers/getFlag';

import store from '@/store';

const getNeuralOptions = () => [
  {
    type: OptionTypes.MultipleFile,
    label: 'Планировка',
    name: 'plans',
  },
  {
    type: OptionTypes.File,
    label: 'Маска',
    name: 'mask',
  },
  {
    type: OptionTypes.Switch,
    label: 'Лог',
    name: 'isNeuralLogEnabled',
  },
];

const getNeuralRules = () => ({
  plans: [
    {
      required: true,
      type: 'array',
    },
  ],
  mask: [],
  isNeuralLogEnabled: [],
});

const getFurnitureOptions = (steps) => {
  const options = [
    {
      type: OptionTypes.Switch,
      label: 'Расстановка света',
      name: 'isFurnitureLightsEnabled',
    },
    {
      type: OptionTypes.Switch,
      label: 'Расстановка штор и радиаторов',
      name: 'isFurnitureCurtainsRadiatorsEnabled',
    },
    {
      type: OptionTypes.Switch,
      label: 'Расстановка мебели',
      name: 'isFurnitureEnabled',
    },
    {
      type: OptionTypes.Switch,
      label: 'Схлопывание балконных стен',
      name: 'isFurnitureGlassWallsCollapseEnabled',
    },
    {
      type: OptionTypes.Select,
      label: 'Дизайн документ',
      name: 'furnitureDesignName',
      options: store.state.furniture.designFiles
        .map((designFiles) => ({ label: designFiles.name, value: designFiles.name })),
    },
  ];

  if (steps.includes(Steps.Neural)) {
    return options;
  }

  return [
    {
      type: OptionTypes.File,
      label: 'JSON',
      name: 'json',
    },
    {
      type: OptionTypes.MultipleFile,
      label: 'Планировка',
      name: 'plans',
    },
    ...options,
  ];
};

const getFurnitureRules = () => ({
  plans: [],
  json: [
    {
      required: true,
      type: 'object',
    },
  ],
  isFurnitureLightsEnabled: [],
  isFurnitureCurtainsRadiatorsEnabled: [],
  isFurnitureEnabled: [],
  isFurnitureGlassWallsCollapseEnabled: [],
  furnitureDesignName: [],
});

const getUnrealOptions = (steps) => {
  const options = [];
  const isEnvironmentVisible = getFlag('isEnvironmentVisible');
  const isNewPlanOptionsVisible = getFlag('isNewPlanOptionsVisible');
  if (!steps.includes(Steps.Neural) && !steps.includes(Steps.Furniture)) {
    options.push(
      {
        type: OptionTypes.File,
        label: 'JSON',
        name: 'json',
      },
      {
        type: OptionTypes.MultipleFile,
        label: 'Планировка',
        name: 'plans',
      },
    );
  }

  options.push(
    {
      type: OptionTypes.Select,
      label: 'Пресет RTX',
      name: 'unrealRtxPreset',
      options: Object.entries(RtxPreset).map((item) => ({ label: item[0], value: item[1] })),
    },
    {
      type: OptionTypes.Select,
      label: 'Пресет RTX для вида сверху',
      name: 'unrealRtxPresetTop',
      options: Object.entries(RtxPreset).map((item) => ({ label: item[0], value: item[1] })),
    },

    {
      type: OptionTypes.Switch,
      label: 'Панорамы',
      name: 'isUnrealPanoramasCaptureEnabled',
    },
    {
      type: OptionTypes.Switch,
      label: 'Съемка сверху',
      name: 'isUnrealTopViewsCaptureEnabled',
    },
    {
      type: OptionTypes.Switch,
      label: 'Съемка сверху вокруг строения',
      name: 'isUnrealTopDownViewsCaptureEnabled',
    },
    {
      type: OptionTypes.Number,
      label: 'Количество фотографий вокруг строения',
      name: 'unrealTopDownViewCount',
    },
    {
      type: OptionTypes.Switch,
      label: 'Срединный срез',
      name: 'isUnrealMiddleCutCaptureEnabled',
    },
    {
      type: OptionTypes.Number,
      label: 'Высота срединного среза',
      name: 'unrealMiddleCutHeight',
    },
    {
      type: OptionTypes.Select,
      label: 'Выбор стилей',
      name: 'style_id',
      options: store.state.substances.stylesList
        .map((stylesList) => ({ label: stylesList.name, value: stylesList.id })),
    },
  );

  if (isEnvironmentVisible) {
    options.push(
      {
        type: OptionTypes.Select,
        label: 'Окружения',
        name: 'unreal_environment_id',
      },
    );
  }

  if (isNewPlanOptionsVisible) {
    options.push(
      {
        type: OptionTypes.Select,
        label: 'Тип панорамы',
        name: 'panoramas_type',
        options: Object.entries(PanoramasType).map(([label, value]) => ({ label, value })),
      },
      {
        type: OptionTypes.Switch,
        label: 'Включить карту глубины',
        name: 'panoramas_render_modes',
      },
      {
        type: OptionTypes.Switch,
        label: 'Включить маску',
        name: 'isMaskEnabled',
      },
      {
        type: OptionTypes.Switch,
        label: 'Включить маску для вида сверху',
        name: 'top_view_render_modes',
      },
      {
        type: OptionTypes.Switch,
        label: 'Включить маску для middle cut',
        name: 'middle_cut_render_modes',
      },
      {
        type: OptionTypes.Number,
        label: 'CameraISO',
        name: 'camera_iso',
      },
      {
        type: OptionTypes.Switch,
        label: 'Скрывать необязательную мебель',
        name: 'hide_optional_furniture',
      },
    );
  }

  return options;
};

const getUnrealRules = (options) => {
  const allRules = {
    json: [
      {
        required: true,
        type: 'object',
      },
    ],
    isUnrealPanoramasCaptureEnabled: [],
    isUnrealTopViewsCaptureEnabled: [],
    isUnrealTopDownViewsCaptureEnabled: [],
    unrealTopDownViewCount: [
      {
        min: 1,
        max: 121,
        type: 'number',
      },
    ],
    unrealRtxPreset: [],
    unrealRtxPresetTop: [],
    panoramas_render_modes: [],
    top_view_render_modes: [],
    middle_cut_render_modes: [],
    hide_optional_furniture: false,
    unreal_environment_id: null,
    panoramas_type: null,
    camera_iso: null,
    isUnrealMiddleCutCaptureEnabled: [],
    unrealMiddleCutHeight: [
      {
        min: 0,
        max: 280,
        type: 'number',
      },
    ],
  };

  return getRulesForOptions(allRules, options);
};

const getOptionsAndRules = (steps) => getOptionsAndRulesBase({
  steps,
  getNeuralOptions,
  getNeuralRules,
  getFurnitureOptions,
  getFurnitureRules,
  getUnrealOptions,
  getUnrealRules,
});

const getFormState = () => ({
  steps: [],

  plans: [],
  mask: null,
  isNeuralLogEnabled: false,

  json: null,

  isFurnitureLightsEnabled: true,
  isFurnitureCurtainsRadiatorsEnabled: true,
  isFurnitureEnabled: true,
  isFurnitureGlassWallsCollapseEnabled: true,
  furnitureDesignName: 'TestDesign',

  isUnrealPanoramasCaptureEnabled: true,
  isUnrealTopViewsCaptureEnabled: true,
  isUnrealTopDownViewsCaptureEnabled: false,
  unrealTopDownViewCount: 1,
  unrealRtxPreset: RtxPreset['No RTX Preview'],
  unrealRtxPresetTop: RtxPreset['No RTX Preview'],
  panoramas_render_modes: [UnrealPanoramaRenderModes.Color],
  top_view_render_modes: [],
  middle_cut_render_modes: [],
  hide_optional_furniture: false,
  unreal_environment_id: null,
  panoramas_type: PanoramasType.Сфера,
  camera_iso: 500,
  isUnrealMiddleCutCaptureEnabled: true,
  unrealMiddleCutHeight: 150,
  style_id: 1,
  build_priority: BuildPriority['Обычный приоритет'],
});

export {
  Steps,
  OptionTypes,
  getOptionsAndRules,
  getFormState,
};
