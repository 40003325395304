<template>
  <a-button
    :style="{ width: '100%' }"
    @click="() => isModalVisible = true">
    Слияние планировки
  </a-button>

  <a-modal
    v-model:open="isModalVisible"
    title="Слияние планировки"
    ok-text="Слить"
    cancel-text="Отмена"
    :ok-button-props="{ disabled: !crmPlanId, loading }"
    @cancel="handleCancel"
    @ok="handleOk">
    <a-input
      v-model:value="crmPlanId"
      type="number"
      min="1"
      placeholder="ID планировки"
      @press-enter="handleOk" />
  </a-modal>
</template>

<script>
import { api } from '@/api';

export default {
  props: {
    currentPlanId: {
      required: true,
      type: Number,
    },
    type: {
      required: true,
      type: Number,
    },
  },
  emits: ['after-ok'],
  data() {
    return {
      crmPlanId: null,
      isModalVisible: false,
      loading: false,
    };
  },
  methods: {
    handleOk() {
      if (!this.crmPlanId) {
        return;
      }

      this.loading = true;

      api.mergeCrmPlan(this.currentPlanId, {
        crmPlanId: this.crmPlanId,
        type: this.type,
      }).then(() => {
        this.$emit('after-ok', this.crmPlanId);
        this.crmPlanId = null;
        this.isModalVisible = false;
      }).finally(() => {
        this.loading = false;
      });
    },
    handleCancel() {
      this.crmPlanId = null;
      this.isModalVisible = false;
    },
  },
};
</script>
