<template>
  <section id="plan-tags">
    <a-space align="center">
      <span
        v-if="!isEditModeEnabled"
        :style="{ display: 'flex', flexWrap: 'wrap' }">
        <a-tag
          v-for="tag in data.crmTags"
          :key="tag.id"
          color="default"
          class="tag">
          <a-tooltip placement="topLeft">
            <template #title>{{ tag.name }}</template>
            {{ tag.name }}
          </a-tooltip>
        </a-tag>
      </span>
      <a-tag
        v-if="!data.crmTags.length && !isEditModeEnabled"
        color="default">
        Отсутствуют
      </a-tag>
      <a-button
        v-if="!isEditModeEnabled && !isNinja"
        @click="setEditMode(true)">
        <template #icon>
          <edit-outlined />
        </template>
      </a-button>
      <div
        v-if="isEditModeEnabled"
        :style="{ display: 'flex', alignItems: 'center', width: '100%' }">
        <a-select
          v-model:value="crmTagsModified"
          mode="multiple"
          :style="{ width: selectWidth }"
          placeholder="Выберите теги"
          @change="attachTagsToPlans">
          <a-select-option
            v-for="tag in tagsList"
            :key="tag.id"
            :value="tag.id">
            {{ tag.name }}
          </a-select-option>
        </a-select>
        <check-circle-outlined
          :style="{ color: '#52c41a', marginLeft: '1rem' }"
          @click="attachTagForPlan" />
        <close-circle-outlined
          :style="{ color: '#f5222d', marginLeft: '1rem' }"
          @click="cancelEdit" />
      </div>
    </a-space>
  </section>
</template>

<script>
import { defineComponent } from 'vue';
import {
  CheckCircleOutlined, CloseCircleOutlined, EditOutlined,
} from '@ant-design/icons-vue';

export default defineComponent({
  components: {
    CloseCircleOutlined,
    CheckCircleOutlined,
    EditOutlined,
  },
  props: {
    tagsList: {
      default: () => [],
      type: Array,
    },
    data: {
      default: () => {},
      type: Object,
      selectWidth: '200px',
    },
  },
  emits: ['attach'],
  data() {
    return {
      isEditModeEnabled: false,
      crmTagsModified: [...this.data.crmTagsModified],
      isNinja: this.$store.getters['auth/currentUserHasRolesWithNames']('ninja'),
    };
  },
  mounted() {
    document.addEventListener('keydown', (event) => {
      if (event.key !== 'Escape') return;
      this.cancelEdit();
    });
    this.updateSelectWidth();
    window.addEventListener('resize', this.updateSelectWidth);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.updateSelectWidth);
  },
  methods: {
    setEditMode(value) {
      this.isEditModeEnabled = value;
    },
    cancelEdit() {
      this.setEditMode(false);
    },
    attachTagForPlan() {
      this.$emit('attach', this.crmTagsModified);
      this.setEditMode(false);
    },
    updateSelectWidth() {
      if (window.innerWidth < 576) {
        this.selectWidth = '200px';
      } else {
        this.selectWidth = '300px';
      }
    },
  },
});
</script>

<style scoped>
.ant-space-align-center {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.tag {
  max-width: 220px;
  margin: 3px 6px 3px 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis
}
</style>
