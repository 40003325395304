import { ref, computed } from 'vue';
import { useStore } from 'vuex';

export function useCrmTagsSelect() {
  const store = useStore();

  const options = ref([]);
  const isLoading = ref(true);

  const allOptions = computed(() => store.state.crm.tagsList.map((item) => ({
    value: item.id,
    label: item.name,
  })));

  const search = (needle) => {
    options.value = allOptions.value.filter(({ label }) => {
      const lowercasedLabel = label.toLowerCase();
      const lowercasedNeedle = needle.toLowerCase();
      return lowercasedLabel.includes(lowercasedNeedle);
    });
  };

  const resetOptions = () => {
    options.value = allOptions.value;
  };

  store.dispatch('crm/getTagsList').then(() => {
    resetOptions();
    isLoading.value = false;
  });

  return {
    options,
    isLoading,
    search,
    resetOptions,
  };
}
