<template>
  <a-descriptions
    :column="{ xxl: 2, xl: 2, lg: 2, md: 1, sm: 1, xs: 1 }"
    :style="{marginTop: '.5rem'}"
    size="small"
    bordered>
    <a-descriptions-item
      :span="2"
      label="Статус">
      <a-row>
        <status-tag :status="getStatusTag(plan.status)" />
      </a-row>
    </a-descriptions-item>

    <a-descriptions-item
      label="Номер планировки">
      <a-tag
        v-if="plan.apartment"
        color="default">
        {{ plan.apartment.plan_number_in_apartment ?? '' }}
      </a-tag>
      <span v-else>Отсутствует</span>
    </a-descriptions-item>

    <a-descriptions-item
      label="Приоритет">
      <span v-if="plan.priority !== null && plan.priority !== undefined">
        {{ planPriorityNames[plan.priority] }}
      </span>
      <span v-else>Обычный</span>
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isUserSupervisor"
      label="Резчик">
      {{ plan.cutter ? plan.cutter.name : "Отсутствует" }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isUserSupervisor"
      label="Ниндзя">
      <div :style="{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }">
        <a-span :style="{marginRight: '.5rem'}">
          {{ plan.ninja ? plan.ninja.name : "Отсутствует" }}
        </a-span>
        <a-button
          v-if="canChangeNinja"
          @click="openChangeNinjaModal">
          <template #icon>
            <edit-outlined />
          </template>
        </a-button>
      </div>
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!isUserSupervisor"
      :span="2"
      label="Менеджер">
      {{ plan.manager ? plan.manager.name : "Отсутствует" }}
    </a-descriptions-item>

    <a-descriptions-item
      label="Слита">
      {{ isMerged ? 'Да' : 'Нет' }}
    </a-descriptions-item>

    <a-descriptions-item label="Слили с планировкой ID">
      <span v-if="!isMerged">-</span>
      <a
        v-else
        target="_blank"
        :href="`${routeForCrmPlan}${plan.merged_from_crm_plan_id}`">{{ plan.merged_from_crm_plan_id }}</a>
    </a-descriptions-item>

    <a-descriptions-item
      v-if="optionsTags"
      :span="2"
      label="Теги">
      <crm-plan-tags-select
        v-if="!isUserSupervisor"
        :data="{
          crmTags: plan.crmTags,
          crmTagsModified: Array.isArray(plan.crmTagsModified) ? plan.crmTagsModified : [],
        }"
        :tags-list="tagsList"
        @attach="attachTagsToPlan" />
    </a-descriptions-item>

    <a-descriptions-item
      v-if="!optionsTags"
      :span="2"
      label="Теги">
      <crm-plan-tags-select
        v-if="!isUserSupervisor"
        :data="{
          crmTags: plan.crmTags,
          crmTagsModified: plan.crmTags.map((t) => t.id),
        }"
        :tags-list="tagsList"
        @attach="attachTagsToPlan" />
    </a-descriptions-item>

    <a-descriptions-item
      v-if="originalPlan"
      label="Оригинал">
      <a :href="`/crm/plans/${originalPlan.id}`">
        {{ originalPlan.name }}
      </a>
    </a-descriptions-item>

    <a-descriptions-item
      v-if="originalPlan"
      label="Статус оригинала">
      <status-tag :status="getStatusTag(Number(originalPlan.status))" />
    </a-descriptions-item>

    <a-descriptions-item
      label="Регион">
      <editable-field
        :value="currentPlan.region"
        @save-data="editRegion" />
    </a-descriptions-item>

    <a-descriptions-item
      label="Застройщик">
      <editable-field
        :value="currentPlan.developer"
        @save-data="editDeveloper" />
    </a-descriptions-item>

    <a-descriptions-item
      label="ЖК">
      <editable-field
        :value="currentPlan.complex"
        @save-data="editComplex" />
    </a-descriptions-item>

    <a-descriptions-item
      label="Адрес">
      <editable-field
        :value="currentPlan.address"
        @save-data="editAddress" />
    </a-descriptions-item>

    <a-descriptions-item
      label="Индивидуальный стиль">
      <span v-if="currentPlan.style_detection_rule === 'developer' && currentPlan.developer">
        {{ currentPlan.developer }}
      </span>

      <span v-else-if="currentPlan.style_detection_rule === 'developer' && currentPlan.developer === null">
        Да
      </span>

      <span v-else>
        Нет
      </span>
    </a-descriptions-item>

    <a-descriptions-item
      v-if="isNewPlanOptionsVisible"
      label="Маска">
      {{ plan.panoramas_render_modes?.object_ids ? "Есть" : "Нет" }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="isNewPlanOptionsVisible"
      label="Карта глубины">
      {{ plan.panoramas_render_modes?.scene_depth ? "Есть" : "Нет" }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="isNewPlanOptionsVisible"
      label="Маска для вида сверху">
      {{ plan.top_view_render_modes?.room_ids ? "Есть" : "Нет" }}
    </a-descriptions-item>

    <a-descriptions-item
      v-if="isNewPlanOptionsVisible"
      label="Маска для middle cut">
      {{ plan.middle_cut_render_modes?.room_ids ? "Есть" : "Нет" }}
    </a-descriptions-item>
  </a-descriptions>
  <a-divider />
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';

import CrmPlanTagsSelect from '@/Components/Service/Plans/CrmPlanTagsSelect.vue';
import { EditOutlined } from '@ant-design/icons-vue';
import EditableField from '@/Components/Common/EditableField.vue';
import { planPriorityNames } from '@/enums/planPriority';
import { getStatusTag } from '@/enums/PlanStatusEnum';
import StatusTag from '@/Components/Common/StatusTag.vue';
import getFlag from '@/helpers/getFlag';

defineProps({
  routeForCrmPlan: {
    type: String,
    default: () => '/crm/plans/',
  },
  attachTagsToPlan: {
    type: Function,
    default: () => null,
  },
  plan: {
    type: Object,
    default: () => ({}),
  },
  planData: {
    type: Object,
    default: () => ({}),
  },
  isUserSupervisor: {
    type: Boolean,
    default: false,
  },
  canChangeNinja: {
    type: Boolean,
    default: false,
  },
  tagsList: {
    type: Object,
    default: () => ({}),
  },
  optionsTags: {
    type: Boolean,
    default: false,
  },
  isMerged: {
    type: Boolean,
    default: false,
  },
});

const store = useStore();
const currentPlan = computed(() => store.state.crm.currentPlan);
const isNewPlanOptionsVisible = getFlag('isNewPlanOptionsVisible');

const openChangeNinjaModal = () => store.commit('crm/setChangeNinjaModalVisibility', true);
const originalPlan = computed(() => store.state.crm.currentPlan.originalCrmPlan);

const editRegion = (newValue) => {
  store.dispatch('crm/changeStrategicData', {
    id: currentPlan.value.id,
    region: newValue,
  });
};

const editDeveloper = (newValue) => {
  store.dispatch('crm/changeStrategicData', {
    id: currentPlan.value.id,
    developer: newValue,
  });
};

const editComplex = (newValue) => {
  store.dispatch('crm/changeStrategicData', {
    id: currentPlan.value.id,
    complex: newValue,
  });
};

const editAddress = (newValue) => {
  store.dispatch('crm/changeStrategicData', {
    id: currentPlan.value.id,
    address: newValue,
  });
};

</script>
